import React, { useEffect } from 'react';
import './app.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Popular from './Components/Popular/Popular';
import Offers from './Components/Offers/Offers';
import About from './Components/About/About';
import Blog from './Components/Blog/Blog';
import Footer from './Components/Footer/Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';
import LoginPage from './Components/login/loginPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Router>
      <>
        <Navbar />
        <Home/>
        <Popular/>
        <Offers/>
        <About/>
        <Blog/>
        <Routes>
          <Route path="/login" element={<LoginPage/>} />
        </Routes>
        <Footer />
      </>
    </Router>
  );
};

export default App;
