import React from 'react'
import './offers.css'
import { MdAirportShuttle, MdBathtub, MdKingBed, MdLocationOn } from 'react-icons/md'
import { FaWifi } from 'react-icons/fa'
import { BsArrowRightShort } from 'react-icons/bs'
import img17 from '../../assets/17.jpg'
import img16 from '../../assets/16.jpg'
import img15 from '../../assets/15.jpg'
import img11 from '../../assets/11.jpg'


 

const OfferData = [
  {
    id: 1,
    imgSrc: img17,
    destTitle: 'Ngorongoro',
    location: 'Serengeti',
    price: '800,000 ',
    discount: '40%'
  },
  {
    id: 2,
    imgSrc: img16,
    destTitle: 'Ngorongoro',
    location: 'Serengeti',
    price: '600,000 ',
    discount: '50%'

  },
  {
    id: 3,
    imgSrc: img15,
    destTitle: 'Ngorongoro',
    location: 'Serengeti',
    price: '400,000 ',
    discount: '20%'


  },
  {
    id: 4,
    imgSrc: img11,
    destTitle: 'Ngorongoro',
    location: 'Serengeti',
    price: '200,000 ',
    discount: '60%'

  },


]






const Offers = () => {
  return (
    <section className='offer container section'>
      <div className="secContainer">
        <div data-aos="fade-up"data-aos-duration="2000" className="secIntro">
          <h2   className='secTitle'>Special Offers</h2>
          <p>From historical cites to natural specteculars, come see the best of the world!
          </p>
        </div>
        <div className="mainContent grid">
         {
          OfferData.map(({id, imgSrc, destTitle, location, price, discount})=>{
            return(
              <div data-aos="fade-up"data-aos-duration="3000"  className="singleOffer">
              <div className="destImage">
                <img src={imgSrc} alt="Image Name" />
  
                <span className='discount'>
                  {discount} Off
                </span>
              </div>
              <div className="offerBody">
                <div className="price flex">
                  <h4>{price} Tsh</h4>
                  <span className="status">
                    For Rent
                  </span>
                </div>
                <div className="amenities flex">
                  <div className="singleAmenity flex">
                    <MdKingBed className='icon'/>
                    <small>2 Beds</small>
                  </div>
  
                  <div className="singleAmenity flex">
                    <MdBathtub className='icon'/>
                    <small>1 Bath</small>
                  </div>
  
                  <div className="singleAmenity flex">
                    <FaWifi className='icon'/>
                    <small>Wi-Fi</small>
                  </div>
  
                  <div className="singleAmenity flex">
                    <MdAirportShuttle className='icon' />
                    <small>shuttle</small>
                  </div>
                </div>
                <div className="location flex">
                  <MdLocationOn className='icon'/>
                  <small>450 Vine #310, {location}</small>
                </div>
                <button className='btn flex'>
                  View Details
                  <BsArrowRightShort className='icon'/>
                </button>
              </div>
            </div>
            )
          })
         }
        </div>
      </div>
    </section>
  )
}

export default Offers
