import React from 'react'
import './popular.css'
import { BsArrowLeftShort, BsArrowRightShort, BsDot } from 'react-icons/bs'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Img from '../../assets/21.jpg'
import img2 from '../../assets/12.jpg'
import img3 from '../../assets/10.jpg'
import img4 from '../../assets/16.jpg'
import img5 from '../../assets/18.jpg'
import img6 from '../../assets/19.jpg'

const Data = [
  {
    id: 1,
    imgSrc: img2,
    destTitle: 'Zanzibar',
    location: 'Kunduchi',
    grade: 'CULTURAL RELAX',
  },

  {
    id: 2,
    imgSrc: img3,
    destTitle: 'Mwanza',
    location: 'Majengo',
    grade: 'CULTURAL RELAX',
  },

  {
    id: 3,
    imgSrc: img4,
    destTitle: 'Dae-es-salaam',
    location: 'Kunduchi',
    grade: 'CULTURAL RELAX',
  },

  {
    id: 4,
    imgSrc: img5,
    destTitle: 'Rukwa',
    location: 'Katavi',
    grade: 'CULTURAL RELAX',
  },
    
]

const Popular = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className='popular section container'>

      <div className="secContainer">

        <div className="secHeader flex">
          <div data-aos="fade-right" className="textDiv">

              <h2  className='secTitle'>Popular Destination</h2>

              <p>From Historical sites to natual specteculars, come see the best of the world</p> 
          </div>

          <div data-aos="fade-left"  className="iconsDiv flex">
            <BsArrowLeftShort className='icon leftIcon'/>
            <BsArrowRightShort className='icon' />
          </div>
      </div>

        <div className="mainContent grid">
          {
            Data.map(({id, imgSrc, destTitle, location, grade})=>{
              return (
                <div data-aos="fade-up"className="singleDestination">
            <div className="destImage">
              <img src={imgSrc} alt="Image Title" />

              <div className='overlayInfo'>
                  <h3>{destTitle}</h3>
                  <p>{location}.</p>
                  <BsArrowRightShort className='icon' />
              </div>
            </div>

            <div className="destFooter">
              <div className="number">
                0{id}
              </div>
              <div className="destText flex">
                <h6>
                  {location}
                </h6>
                <span className="flex">
                  <span className="dot">
                    <BsDot className='icon'/>
                    
                  </span>
                  Dot
                </span>
              </div>
            </div>
          </div>
              )
            })
          }
        
        </div>
      </div>
    </section>
  )
}

export default Popular
