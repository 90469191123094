import React from 'react'
import './about.css'

import hikings from '../../assets/hiking.png'
import mountain from '../../assets/mountain.png'
import support from '../../assets/support.png'

import video from '../../assets/video.mp4'


const About = () => {
  return (
    <section className='about section'>
      <div className="secContainer">
        <h2 className="title">
          Why Hikings ?
        </h2>

        <div className="mainContent container grid">
          <div data-aos="fade-up"data-aos-duration="2000"  className="singleItem">
            <img src={mountain} alt="Image Name" />
            <h3>100+ Mountains</h3>

            <p>Research Shows that a chance to break from the normal rythms of daily life reduces stress and improves health and well-being</p>
          </div>
          <div data-aos="fade-up"data-aos-duration="2500"  className="singleItem">
            <img src={hikings} alt="Image Name" />
            <h3>1000+ Hikings</h3>

            <p>Research Shows that a chance to break from the normal rythms of daily life reduces stress and improves health and well-being</p>
          </div>
          <div data-aos="fade-up"data-aos-duration="3000"  className="singleItem">
            <img src={support} alt="Image Name" />
            <h3>2000+ Customers</h3>

            <p>Research Shows that a chance to break from the normal rythms of daily life reduces stress and improves health and well-being</p>
          </div>
        </div>

        <div className="videoCard container">
          <div className="cardContent grid">
            <div data-aos="fade-right"data-aos-duration="2000"  className="cardText">
              <h2> Wonderful House experience in there!</h2>

              <p>
                The Adventure subranking is based in an equally weighted average of scores from five countries
              </p>

            </div>
            <div data-aos="fade-left"data-aos-duration="2000"  className="cardVideo">
              <video src={video} autoPlay loop muted typeof='video/mp4'></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
