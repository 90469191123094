import React from 'react'
import './footer.css'
import { SiFloatplane, SiYamahamotorcorporation } from 'react-icons/si'
import { ImFacebook } from 'react-icons/im'
import { BsTwitter } from 'react-icons/bs'
import { AiFillInstagram } from 'react-icons/ai'
import { GiPaperPlane } from 'react-icons/gi'
 
const Footer = () => {
  return (
    <div className='footer'>
      <div className='secContainer container grid'>
        <div className="logoDiv">
          <div className="footerLogo" data-aos="fade-up" data-aos-delay="1000">
            <a href="#" className='logo flex'>
              <h1 className="flex">
                <GiPaperPlane className='icon'/>
                Dot
              </h1>

            </a>
          </div>

          <div className="socials flex" data-aos="fade-up" data-aos-delay="2000">
          <ImFacebook className='icon'/>
          <BsTwitter className='icon'/>
          <AiFillInstagram className='icon'/>
        </div>
        </div>
        <div className="footerLinks" data-aos="fade-up" data-aos-delay="2500">
        <span className='linkTitle'>
          Information
        </span>
  
        <li>
          <a href="#">Home </a>
        </li>
        <li>
          <a href="#">Explore </a>
        </li>
        <li>
          <a href="#">Blog </a>
        </li>
        <li>
          <a href="#">Travel </a>
        </li>

       </div>

       <div className="footerLinks" data-aos="fade-up" data-aos-delay="3000">
        <span className='linkTitle'>
          Helpful Links
        </span>
  
        <li>
          <a href="#">Destination </a>
        </li>
        <li>
          <a href="#">Terms & Condition </a>
        </li>
        <li>
          <a href="#">Support </a>
        </li>
       </div>
       <div className="footerLinks" data-aos="fade-up" data-aos-delay="3000">
        <span className='linkTitle'>
         Contact us
        </span>
  
        <li>
          <a href="#">+255 789 342 33</a>
        </li>
        <li>
          <a href="#">solutiontechcompay732@gmail.com</a>
        </li>
      
       </div>
      
      </div>
    </div>
  )
}

export default Footer
