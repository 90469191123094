import React from 'react'

const loginPage = () => {
  return (
    <div>
      This is a Login page
    </div>
  )
}

export default loginPage
