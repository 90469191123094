import React from 'react'
import './blog.css'
import { BsArrowRightShort } from 'react-icons/bs'
 
import img from '../../assets/13.jpg'
import img1 from '../../assets/2.jpg'
import img2 from '../../assets/8.jpg'
import img3 from '../../assets/20.jpg'


const Posts = [
  {
    id: 1,
    title: 'Beautiful Ngorongoro, let us travel!',
    desc: 'The Kingdom of Ngorongoro is a among the most beautiful amazing place ever found in tanzania having natural landscape and beautiful vegetation',
    postImg: img,
    aosDelay: "100"
  },
  {
    id: 2,
    title: 'Beautiful Ngorongoro, let us travel!',
    desc: 'The Kingdom of Ngorongoro is a among the most beautiful amazing place ever found in tanzania having natural landscape and beautiful vegetation',
    postImg: img1,
    aosDelay: "200"
  },
  {
    id: 3,
    title: 'Beautiful Ngorongoro, let us travel!',
    desc: 'The Kingdom of Ngorongoro is a among the most beautiful amazing place ever found in tanzania having natural landscape and beautiful vegetation',
    postImg: img2,
    aosDelay: "250"
  },
  {
    id: 14,
    title: 'Best  Place in Tanzania..',
    desc: 'The Kingdom of Ngorongoro is a among the most beautiful amazing place ever found in tanzania having natural landscape and beautiful vegetation',
    postImg: img3,
    aosDelay: "300"
  },
]


const Blog = () => {
  return (
    <section className='blog container section'>
      <div className='secContainer'>
        <div data-aos="fade-up" data-aos-delay="200" className="secIntro">
          <h2 className="secTitle">
          Our Best Blog
          </h2>
          <p>
            An insight to the incredible experience in the world!
          </p>
        </div>
        <div className="mainContainer grid">
          {
            Posts.map(({title, id, postImg, desc, aosDelay})=>{
              return (
                <div data-aos="fade-up" data-aos-delay={aosDelay} className="singlePost grid">
                    <div className="imgDiv">
                      <img src={postImg} alt="Image Name" />
                    </div>

                    <div data-aos="fade-up" data-aos-delay={aosDelay} className="postDetails">
                      <h3>
                        {title}
                      </h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima, tempora!
                      </p>
                    </div>
                    
                    <a href="#" className="flex" data-aos="fade-up" data-aos-delay={aosDelay} >
                      Read More
                      <BsArrowRightShort className='icon'/>
                    </a>
                  </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default Blog
